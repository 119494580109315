<template>
    <div class="add-new-employee-type">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.employeeTypeUpdate')"
            :title="$t('messages.employeeTypeUpdate')"
            @submitPressed="submitPressed"
        />
        <resource-form v-if="entityOption" :resource-object="entityOption" :resource="$EntityOptions" base-url="/settings/employee-types" create-message="messages.employeeTypeCreated" update-message="messages.employeeTypeUpdated" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            entityOption: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$EntityOptions.getResource({ id }).then((response) => {
                this.entityOption = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
